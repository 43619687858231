<script>
import {  common } from '@/mixins/common';

export default {
  name: 'CompleteOrderDialog',
  components: {},
  props: {
    displayDialog: {
        type: Boolean,
        required: true
    },
    data: {
        type: Object,
        required: true,
        default() {
            return {
                totalRemainingItems: 0,
                totalItemsScanned: 0,
                totalOrderRemaining: 0,
                orders: []
            }
        }
    }
  },
  data() {
    return {
        form: {
            barcode: ''
        }
    }
  },
  mixins: [
    common
  ],
  methods: {
    submitForm() {
      /*  let _vm = this.
        _vm.$refs.formContainer.validate((valid) => {
            if (valid) {
                PostApiWithAuthorizationAction("admin/products/identifier/save", {
                    ... _vm.form,
                    binNumber: this.binNumber
                })
                .then(res => {
                    if (res.status == 'success') {
                        _vm.resetForm({
                            itemNumber: _vm.form.itemNumber+1
                        })
                    }
                });
            }
        });*/
    },
  },
  computed: {
    selectedOrder() {
        const _vm = this
        const order = _vm.data.orders.length > 0 ? _vm.data.orders[0] : {extendedOrderID: []}
        // eslint-disable-next-line
        console.log(order)
        return order
    }
  }
}
</script>

<template>
    <el-dialog
      custom-class="el-dialog-bs-info"
      :visible.sync="displayDialog"
      width="40%">
      <span slot="title"><h5><i class="fas fa-qrcode"></i> COMPLETE ORDER : {{ selectedOrder.extendedOrderID ? selectedOrder.extendedOrderID[0] : '' }}</h5></span>
      <el-form
        :model="form"
        @submit.native.prevent="submitForm"
        ref="formContainer"
        id="identifierForm">
        <div class="mb-1 pt-1 text-center">
            <el-button type="primary" @click="viewList()">
                <i class="fas fa-list-alt"></i> ITEMS LIST
            </el-button>
        </div>
        <div style="margin-top: 15px;">
          <el-input
            ref="drawerPrimaryField"
            autofocus
            placeholder="Type/Scan Barcode..."
            v-model="form.barcode"
            class="input-with-select">
            <el-button
              slot="append"
              type="success"><i class="fas fa-paper-plane" style="transform: rotate(50deg);"></i></el-button>
          </el-input>
        </div>
        <div class="text-center">
            <div id="order-barcode-remaining-count-div">
                <span id="warning-item-remaining"></span>
                <span class="span-order-count-item">{{ data.totalRemainingItems }}</span> items remaining
            </div>
            <div id="order-barcode-scanned-count-div">
                <span class="span-order-count-item">{{  data.totalItemsScanned }}</span> items scanned
            </div>
            <div id="order-remaining-count-div" v-if="data.orders.length > 1">
                <span class="span-order-count-remaining">{{ data.totalOrderRemaining }}</span> orders remaining
            </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer text-right">
        <el-button
          @click="displayDialog = false"
          type="danger">PAUSE</el-button>
      </span>
    </el-dialog>
</template>