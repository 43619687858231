<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {
GetApiActionWithAuthorization,
  PostApiWithAuthorizationAction
} from "../../../helpers/apiActions";
import moment from 'moment-timezone';
import momentBusiness from 'moment-business-days'
import { common } from '@/mixins/common'
import ViewOrderItemList from '@/components/drawers/order/viewItemList'
import CompleteOrderDialog from '../../../components/dialogs/order/CompleteOrderDialog'

// import { isMobile, isTablet } from 'mobile-device-detect';
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Orders"
  },
  components: {
    Layout,
    PageHeader,
    ViewOrderItemList,
    CompleteOrderDialog
  },
  data() {
    return {
      title: "Orders",
      allOrdersChecked: true,
      isallOrdersIndeterminate: true,
      displayCompleteOrderDialog: false,
      items: [],
      selectedOrders: [],
      shippingServices: [],
      selectedOrder: {},
      stores: [],
      tabs: [
        {
          value: 'Ready To Ship',
          link: 'ready_to_ship',
          icon: 'fas fa-cart-plus',
          active: false
        },
        {
          value: 'Completed',
          link: 'completed',
          icon: 'fas fa-dolly',
          active: false
        },
        {
          value: 'Flagged',
          icon: 'fas fa-flag',
          link: '_flagged',
          active: false
        },
        {
          value: 'Unpaid',
          icon: 'fas fa-money-check',
          link: 'unpaid',
          active: false
        },
        {
          value: 'Cancelled',
          icon: 'fas fa-ban',
          link: 'cancelled',
          active: false
        },
      ],
      columns: [
        {
          key: "id",
          value: 'Order #',
          sortable: false,
        },
        {
          key: "products",
          value: 'Products',
          sortable: false,
          center: true
        },
        {
          key: "amount",
          value: 'Amount',
          sortable: false,
          center: true
        },
        {
          key: "quantity",
          value: 'Qty',
          sortable: false,
          center: true
        },
        {
          key: "comment",
          value: 'Desc. & Comments',
          sortable: false,
        },
        {
          key: "action",
          value: 'Actions',
          sortable: false,
          center: true
        }
      ],
      formFilter: {
        tab: '',
        pageSize: 50,
        page: 1,
        sortBy: '',
        searchAttribute: 'systemOrderId',
        searchTab: '',
        searchQuery: '',
        appId: '',
        combined: true
      },
      formFilterByOptions: [
        {
          key: 'systemOrderId',
          value: 'System Order Number'
        },
        {
          key: 'extendedOrderID',
          value: 'Ebay Order Number'
        },
        {
          key: 'salesRecordNumber',
          value: 'Ebay SR Number'
        },
        {
          key: 'buyerUserID',
          value: 'Buyer Name'
        },
        {
          key: 'barcode',
          value: 'Item Barcode'
        },
        {
          key: 'binId',
          value: 'BIN Number'
        },
        {
          key: 'shipmentTrackingNumber',
          value: 'Tracking#'
        },
      ],
      expandItems: {},
      orderApps: [],
      tableData: [],
      systemSetting: {},
      hideCount: true,
      tabCount: {
        ready_to_ship: 0,
        completed: 0,
        _flagged: 0,
        unpaid: 0,
        cancelled: 0
      },
      onPageTabLoad: true,
      showOrdersToComplete: false,
      displayOrderItemPopup: false,
      orderItems: [],
      selectedOrderId: [],
      itemPagination: {
        page_size: 10,
        total: 1,
        page: 1
      },
      //
      completedData: {
        orders: []
      }
    };
  },
  computed: {
    isReadyToShip() {
      return this.formFilter.tab === 'ready_to_ship'
    },
    isCommonTab() {
      return ['ready_to_ship', 'completed'].includes(this.formFilter.tab)
    },
    tabTitle () {
      let _tab = this.tabs.findIndex(m=>m.link==this.formFilter.tab)
      return _tab.value
    },
    ebayStoreUrl () {
      return process.env.NODE_ENV === "development" ? "http://sandbox.ebay.com" : "http://ebay.com"
    }
  },
  mixins: [
    common
  ],
  mounted() {
    let _vm = this
    //auto load table page size
    _vm.formFilter.pageSize = _vm.loadPageSize()
    //auto select default tab
    _vm.formFilter.tab = _vm.$route.query.tab || 'ready_to_ship'
    _vm.formFilter.searchTab = _vm.formFilter.tab
    let findActiveTabIndex = _vm.tabs.findIndex(m=>m.link==_vm.formFilter.tab) || 0
    _vm.$set(_vm.tabs, findActiveTabIndex, Object.assign(_vm.tabs[findActiveTabIndex], {
      active: true
    }));
    //
    _vm.loadPageData().then(()=>{
      return _vm.loadOrders()
    })
    _vm.$root.$on('show-order-item-drawer', params => {
      _vm.viewOrderItems(params)
    })
  },
  methods: {
    toggleAll() {
      const _vm = this
      for(let i = 0; i < _vm.tableData.length; i++) {
        let _row = _vm.tableData[i]
        if (_row.extendedOrderID.length == 1) {
          _row.orderChecked = _vm.allOrdersChecked
          //
          if (_vm.allOrdersChecked
              && !_vm.selectedOrders.includes(_row.orderId[0])) {
            _vm.selectedOrders.push(_row.orderId[0])
          }
        }
      }
      //
      if (!_vm.allOrdersChecked) {
        _vm.selectedOrders = []
      }
      _vm.showOrdersToComplete = true
      _vm.isallOrdersIndeterminate = false
    },
    toggleSingle(index) {
      const _vm = this
      const _row = _vm.tableData[index]
      if (!_row.orderChecked) {
        _vm.allOrdersChecked = false
        _vm.selectedOrders = _vm.selectedOrders.filter(v=>v!=_row.orderId[0])
      } else {
        if (!_vm.selectedOrders.includes(_row.orderId[0])) {
          _vm.selectedOrders.push(_row.orderId[0])
        }
      }
      _vm.showOrdersToComplete = true
    },
    isShippingOverdue(index) {
      const _vm = this,
      _row = _vm.tableData[index],
      orderHandlingTime = _vm.systemSetting.handlingTime*24*3600,
      todayTimestamp = momentBusiness().isBusinessDay() ? moment().unix() : momentBusiness().nextBusinessDay().unix(),
      orderDate = _row.paidDate ? _row.paidDate : _row.createdDate,
      maxOrderHandlingTime = momentBusiness((orderDate + orderHandlingTime) * 1000).nextBusinessDay().endOf('day').unix()
      return (todayTimestamp > maxOrderHandlingTime) && _row.complete == 0
    },
    getShippingService(deliveryServiceSelected) {
      return (typeof this.shippingServices[deliveryServiceSelected] !== 'undefined')
              ? this.shippingServices[deliveryServiceSelected].description.replace(' ', '&nbsp;')
              : deliveryServiceSelected
    },
    isComplete(order) {
      return order.complete && order.ebayStatus == 'completed';
    },
    getFormattedData(timestamp) {
      return moment(timestamp*1000).format('YYYY-MM-DD HH:mm:ss')
    },
    hideItems(index) {
      this.tableData[index].OrderItems = [this.tableData[index].OrderItems[0]]
      this.tableData[index].expand = false
    },
    expandOrderItems(orderId) {
      const _vm = this
      return GetApiActionWithAuthorization("admin/orders/items", {
        id: orderId
      }).then(res => {
        const items = res.data.map(v => {
          return {
            ... v,
            ... v.Auction,
            ... v.Auction.AuctionOwner
          }
        })
        _vm.$set(_vm.expandItems, orderId, items)
        return items
      });
    },
    viewOrderItems(params = {}) {
      const _vm = this
      _vm.selectedOrderId = params.orderId
      return GetApiActionWithAuthorization("admin/orders/items", {
        ... _vm.itemPagination,
        ... params,
          id: params.orderId
      }).then(res => {
        _vm.orderItems = res.data.map(v => {
          return {
            ... v.Auction,
            AuctionAttachments: v.AuctionAttachments
          }
        })
        _vm.itemPagination.page_size = res.page_size
        _vm.itemPagination.total = res.count
        if (res.data.length > 0) {
          _vm.itemPagination.page = params.page
        }
        //
        _vm.displayOrderItemPopup = true
      });      
    },
    loadOrderItems(index) {
      const _vm = this
      const data = _vm.tableData[index]
      return GetApiActionWithAuthorization("admin/orders/items", {
        id: data.orderId,
        excludeID: data.OrderItemID
      }).then(res => {
        for (const _data of res.data) {
          _vm.tableData[index].OrderItems.push({
            ... _data.Auction,
            ... _data.Auction.AuctionOwner,
            ... _data.Order
          })
        }
        //
        _vm.tableData[index].expand = true
      });
    },
    loadOrders (params = {}) {
      let _vm = this
      return PostApiWithAuthorizationAction("admin/orders/all", {
        filter: Object.assign(_vm.formFilter, params)
      }).then(res => {
        _vm.tableData = res.data
        _vm.shippingServices = res.services
        if (!params.search) {
          for (const prop in res.count) {
            if (res.count.hasOwnProperty(prop)) {
              _vm.tabCount[prop] = res.count[prop]
            }
          }
        } else {
          _vm.tabCount[_vm.formFilter.tab] = res.count[_vm.formFilter.tab]
        }
        _vm.selectedOrders = res.data.filter(v=>v.orderChecked===true).map(v=>v.orderId[0])
        _vm.orderApps = res.stores
        _vm.hideCount = false
      });
    },
    loadPageData () {
      let _vm = this
      return GetApiActionWithAuthorization("admin/orders/get-page-data").then(res => {
        // _vm.stores = res.applications
        _vm.systemSetting = res.setting
      });
    },
    preventTabClick(tab, oldTab) {
      if (typeof oldTab === 'string' && oldTab.length > 1) {
        location.href = '/orders/index?tab=' + tab
      }
      return this.onPageTabLoad && (oldTab == 0 || !oldTab);
    },
    handleTabClick() {
      this.onPageTabLoad = false
      return this.onPageTabLoad;
    },
    hasShippingAddress(order) {
      return this.getShippingAddress(order) !== ""
    },
    getShippingAddress(order) {
      let shippingAddress = order.shippingAddress
      shippingAddress += (order.shippingCity) ? "," + order.shippingCity : ""
      shippingAddress += (order.shippingState) ? "," + order.shippingState : ""
      shippingAddress += (order.shippingPostalCode) ? "," + order.shippingPostalCode : ""
      return shippingAddress
    },
    hasVoidLabel(order) {
      return order.ebayPrinted == 0 && order.localPrinted == 0
    },
    viewList () {
      // this.$root.$emit('display-order-items-drawer', this.binNumber)
    },
    completeAllOrder() {
      let _vm = this
      return PostApiWithAuthorizationAction("admin/orders/get-complete-data", {
        orders: _vm.selectedOrders.map(v=>v.orderId)
      }).then(res => {
        _vm.displayCompleteOrderDialog = true
        _vm.completedData = {
          ... res.data,
          totalOrderRemaining: _vm.selectedOrders.length,
          orderIds: _vm.tableData.filter(o=>o.orderChecked===true)
        }
      }); 
    },
    completeOrder(order) {
      let _vm = this
      return PostApiWithAuthorizationAction("admin/orders/get-complete-data", {
        orders: order.orderId
      }).then(res => {
        if (res.status === "success") {
          _vm.displayCompleteOrderDialog = true
          _vm.completedData = {
            ... res.data,
            orderIds: [order]
          }
        }
      }); 
    },
  },
  watch: {
    'formFilter.page': function(newValue) {
      this.loadOrders({
        page: newValue
      })
    }
  }
};
</script>

<template>
  <div class="order-listing-page">
    <CompleteOrderDialog
        :displayDialog="displayCompleteOrderDialog"
        :data="completedData"/>
    <el-drawer
      ref="orderItemDrawer"
      :title="'ORDER ITEMS'"
      :visible.sync="displayOrderItemPopup"
      :close-on-press-escape="false"
      :with-header="true"
      :wrapperClosable="false"
      size="70%"
      :append-to-body="true"
      custom-class="common-drawer demo-drawer">
      <ViewOrderItemList
        :items="orderItems"
        :orderId="selectedOrderId"
        :pagination="itemPagination"/>
    </el-drawer>
    <Layout>
      <PageHeader
        :title="title"
        :subTitle="tabTitle"
        :items="items"
        icon="fas fa-gem" />
      <div class="order-page-container">
        <el-row>
          <el-col
            :xs="24"
            :sm="5"
            :md="5"
            :lg="5"
            :xl="5">
              <b-button
                variant="primary"
                @click="syncOrders()"
                block>
                <i class="fas fa-sync font-size-16"></i> Pull New Ebay Orders
              </b-button>
          </el-col>
          <el-col
            :xs="24"
            :sm="19"
            :md="19"
            :lg="19"
            :xl="19">
            <div class="filter-row text-right">
              <div class="">
                <el-form
                  ref="form"
                  :model="formFilter"
                  :inline="true"
                  @submit.native.prevent="loadOrders({ ... defaultPaginationParams, search: true })"
                  class="form-inline-custom mb-0"
                >
                  <el-form-item>
                    <el-input
                      :placeholder="'Filter Orders By'"
                      v-model="formFilter.searchQuery"
                      clearable>
                    </el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-select v-model="formFilter.searchAttribute" placeholder="Filter Type">
                        <el-option
                            v-for="(option, index) in formFilterByOptions"
                            :key="index"
                            :label="option.value"
                            :value="option.key"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-select v-model="formFilter.searchTab" placeholder="Orders Status">
                        <el-option
                            v-for="(option, index) in tabs"
                            :key="index"
                            :label="option.value"
                            :value="option.link"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item style="margin-right: 0">
                    <el-button
                      type="success"
                      native-type="submit"
                      icon="el-icon-search"
                      circle
                    ></el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-tabs
          ref="tableOrders"
          type="border-card"
          v-model="formFilter.tab"
          :before-leave="preventTabClick"
          @tab-click="handleTabClick"
          stretch
        >
          <el-tab-pane
            v-for="tab in tabs"
            :key="tab.link"
            :active="tab.active"
            :name="tab.link"
          >
            <template #label>
              <a
                :href="'/orders/index?tab=' + tab.link"
                class="product-tab-link">
                <i :class="['font-size-16', tab.icon]"></i> {{ tab.value }}
                <span
                  :id="tab.link + '-count'"
                  class="tab-span-count">{{ !hideCount ? tabCount[tab.link] : '' }}</span>
              </a>
            </template>
            <div
              class="data-table"
              v-if="tab.link==formFilter.tab">
              <div class="table-responsive mb-0">
                <div
                  class="text-right"
                  >
                  <el-form
                    ref="form"
                    :model="formFilter"
                    :inline="true"
                    class="form-inline-custom mb-0"
                    style="font-size: 16px"
                  >
                    <label class="pt-2" v-if="isReadyToShip">Combined Orders: </label>
                    <el-switch
                      v-model="formFilter.combined"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      class="mx-2"
                      @change="loadOrders({ ... defaultPaginationParams })"
                      v-if="isReadyToShip">
                    </el-switch>
                    <b-button
                      variant="warning"
                      @click="scanOrder()"
                      class="mx-1"
                      v-if="isReadyToShip">
                      <i class="fas fa-qrcode font-size-16"></i>  SCAN ORDER
                    </b-button>
                    <b-button
                      variant="info"
                      @click="completeAllOrder()"
                      class="mx-1"
                      :disabled="selectedOrders.length==0"
                      v-if="isReadyToShip">
                      <i class="fas fa-qrcode font-size-16"></i> COMPLETE ALL <span v-if="showOrdersToComplete">({{ selectedOrders.length }})</span>
                    </b-button>
                    <b-button
                      variant="success"
                      @click="completeOrder()"
                      class="mx-1"
                      v-if="isReadyToShip">
                      <i class="fas fa-print font-size-16"></i> PURCHASE & PRINT ALL
                    </b-button>
                    <b-button
                      variant="secondary"
                      @click="completeOrder()"
                      class="m-1"
                      v-if="isReadyToShip">
                      <i class="fas fa-barcode font-size-16"></i> SCAN FORM
                    </b-button>
                    <label class="pt-2 mx-1">Store: </label>
                    <el-select
                      v-model="formFilter.appId"
                      placeholder="Stores"
                      @change="loadOrders({ ... defaultPaginationParams })"
                      class="mx-1"
                      style="width: auto !important">
                      <el-option
                        label="All Stores"
                        value=""></el-option>
                      <el-option
                        v-for="(orderApp, index) in orderApps"
                        :key="index"
                        :label="orderApp.name"
                        :value="orderApp.id"></el-option>
                    </el-select>
                  </el-form>
                </div>
                <el-table
                  :data="tableData"
                  style="width: 100%"
                  header-align="center"
                  :empty-text="' '"
                  class="my-1 el-table-effect"
                  stripe>
                  <el-table-column
                    width="35"
                    v-if="formFilter.tab == 'ready_to_ship'">
                    <template
                      slot="header">
                      <b-form-checkbox
                        v-model="allOrdersChecked"
                        @change="toggleAll"></b-form-checkbox>
                    </template>
                    <template slot-scope="scope">
                      <b-form-checkbox
                        v-model="scope.row.orderChecked"
                        v-if="scope.row.extendedOrderID.length == 1"
                        @change="toggleSingle(scope.$index)"></b-form-checkbox>
                      <i class="fas fa-compress" v-else></i>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Order #"
                    width="165">
                    <template slot-scope="scope">
                      <div
                        v-if="formFilter.tab !== 'unpaid'">
                        <div v-if="scope.row.shipmentTrackingNumber === ''">
                          <el-tooltip
                            v-if="scope.row.requireConfigurationUpdate == 1"
                            class="item"
                            effect="dark"
                            placement="right">
                            <div
                              style="color: red;"
                              class="blink">
                              <i class="fas fa-robot"></i><span style="font-size: 14px;"> Errors detected</span>
                            </div>
                            <div slot="content">
                              Some errors have been found while checking the print label configuration.<br/>
                              Please fix it before purchase a label for that order.
                            </div>
                          </el-tooltip>
                        </div>
                        <div style="color: red;" v-if="isShippingOverdue(scope.$index)">
                          <i class="fas fa-robot"></i>
                          <span style="font-size: 14px;">Shipping Overdue</span>
                        </div>
                        <el-tag
                          size="mini"
                          effect="dark"
                          type="danger"
                          v-if="scope.row.status==='Cancelled'">
                          Cancelled
                        </el-tag>
                      </div>
                      <div>
                          <div class="mb-1">
                            <el-tag
                              type="info"
                              effect="dark"
                              :style="{ backgroundColor: scope.row.Application.color }"
                              size="mini">
                              {{ scope.row.Application.name }}
                            </el-tag>
                          </div>
                          <div class="mb-1" v-if="scope.row.systemOrderId">
                            <span style="font-weight: 600;font-style: italic;">
                              {{ scope.row.systemOrderId }}
                            </span>
                          </div>
                          <div
                            v-for="(extendedOrderID, index) in scope.row.extendedOrderID"
                            :key="index">
                            <div class="" v-if="extendedOrderID != ''">
                              <el-link
                                :underline="false"
                                type="primary"
                                target="_blank"
                                :href="ebayStoreUrl + '/' + 'sh/ord/details?srn=' + scope.row.salesRecordNumber[index] + '&orderid=' + extendedOrderID">
                                {{ extendedOrderID }}
                              </el-link>
                            </div>
                            <div class="">
                              <el-link
                                :underline="false"
                                type="primary"
                                target="_blank"
                                :href="ebayStoreUrl + '/' + 'sh/ord/details?srn=' + scope.row.salesRecordNumber[index] + '&transid=0'">
                                {{ scope.row.salesRecordNumber[index] }}
                              </el-link>
                            </div>
                            <hr
                              style="margin:0"
                              v-if="scope.row.extendedOrderID.length > 1"/>
                          </div>
                          <div
                            class="order_tracking_number"
                            v-if="scope.row.shipmentTrackingNumber != ''">
                            <el-link
                                :underline="false"
                                type="primary"
                                target="_blank"
                                :href="'https://jewelgiant.com/tracking/' + scope.row.shipmentTrackingNumber">
                                {{ scope.row.shipmentTrackingNumber }}
                              </el-link>
                          </div>
                          <div
                            class=""
                            v-if="scope.row.OrderBuyer">
                            <el-tooltip
                              class="item display-inline"
                              effect="dark"
                              :content="scope.row.OrderBuyer.firstname + ' ' + scope.row.OrderBuyer.lastname + ' has already bought some items from us.'"
                              placement="top-start"
                              v-if="scope.row.buyerOrdersCount > 1">
                              <i class="fas fa-star" style="color: #a0ec1b;"></i>
                            </el-tooltip>
                            <el-link
                              class="display-inline font-weight-400"
                              :underline="false"
                              type="primary"
                              target="_blank"
                              :href="ebayStoreUrl + '/usr/' + scope.row.buyerUserID">
                              {{ scope.row.OrderBuyer.firstname }} {{ scope.row.OrderBuyer.lastname }}
                            </el-link>
                          </div>
                        </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Products" min-width="330">
                    <template slot-scope="scope">
                      <el-table
                        :data="scope.row.OrderItems"
                        style="width: 100%"
                        class="order-items-table px-1"
                        :show-header="false">
                        <el-table-column>
                          <template slot-scope="productScope" min-width="350">
                            {{ productScope.row.title }}&nbsp;<small
                              style="font-style: italic;font-weight: bold"
                              v-if="scope.row.extendedOrderID.length > 1">-&nbsp;{{ productScope.row.itemExtendedOrderID }}
                            </small>-&nbsp;
                            <span class="owner-fullname font-weight-bold">{{ productScope.row.firstName }}&nbsp;{{ productScope.row.lastName[0] + '.'}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column width="70">
                          <template slot-scope="productScope">
                            <span class="font-weight-bold" style="color: red">
                              B {{ formatBinNumber(productScope.row.binId) }}
                            </span>
                          </template>
                        </el-table-column>
                        <el-table-column width="70">
                          <template slot-scope="productScope">
                            <div class="">
                              <el-link
                                :underline="false"
                                type="primary"
                                class="font-weight-bold"
                                target="_blank"
                                :href="ebayStoreUrl + '/' + 'itm/' + productScope.row.ebayId">
                                {{ productScope.row.barcode }}
                              </el-link>
                            </div>
                            <div class="text-center">
                              <el-button
                                size="mini"
                                type="danger"
                                circle>
                                <i class="el-icon-refresh-left font-size-16 font-weight-bold"/>
                              </el-button>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                      <div
                        class="pt-1 text-right"
                        v-if="scope.row['OrderItem.quantity'] > 1">
                        <span
                          class="expand"
                          @click="loadOrderItems(scope.$index)"
                          v-if="!scope.row.expand">
                          [+] <span class="text">Expand</span>
                        </span>
                        <span
                          class="hide"
                          v-else
                          @click="hideItems(scope.$index)">[-] <span class="text">Hide</span>
                        </span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Amount" min-width="90">
                    <template slot-scope="scope">
                      <el-popover
                        placement="right"
                        title="Amount"
                        width="300"
                        popper-class="order-amount-popover"
                        :visible-arrow="true"
                        trigger="click">
                        <div
                          v-for="(extendedOrderID, index) in scope.row.extendedOrderID"
                            :key="index">
                            <div class="el-popover-item-line">
                              <el-link
                                :underline="false"
                                type="primary"
                                class="font-weight-bold"
                                target="_blank"
                                v-if="extendedOrderID != ''"
                                :href="ebayStoreUrl + '/' + 'sh/ord/details?srn=' + scope.row.salesRecordNumber[index] + '&orderid=' + extendedOrderID">
                                {{ extendedOrderID }}
                              </el-link>
                              <el-link
                                :underline="false"
                                type="primary"
                                class="font-weight-bold"
                                target="_blank"
                                v-else
                                :href="ebayStoreUrl + '/' + 'sh/ord/details?srn=' + scope.row.salesRecordNumber[index] + '&transid=0'">
                                {{ scope.row.salesRecordNumber[index] }}
                              </el-link>: ${{ scope.row.total[index] }}
                              <el-link
                                class="expand-item"
                                :underline="false"
                                type="danger"
                                @click="expandOrderItems(scope.row.orderId[index])"
                                v-if="!expandItems[scope.row.orderId[index]]">
                                [+]
                              </el-link>
                              <ul
                                class="pl-2 mb-0"
                                v-if="expandItems[scope.row.orderId[index]]">
                                <li
                                  v-for="(orderItem, _index) in expandItems[scope.row.orderId[index]]"
                                  :key="_index"
                                  style="font-style: italic;font-weight: 300;list-style-type: square;">
                                  {{ orderItem.totalQuantity }}x {{ orderItem.barcode }} (B {{ formatBinNumber(orderItem.binId) }}) : ${{ orderItem.totalPurchasedPrice }}
                                </li>
                              </ul>
                            </div>
                            <div class="el-popover-item-line" style="font-size: 0.8rem;">
                              Discount: {{ '$' + scope.row.discountPrice[index] }}
                            </div>
                            <div class="el-popover-item-line" style="font-size: 0.8rem;">
                              Shipping Fee: {{ scope.row.shippingServiceCost[index] == 0 ? 'Free' : '$' + scope.row.shippingServiceCost[index] }}
                            </div>
                            <hr style="margin:2px" class="mx-1">
                          </div>
                          <div class="text-center">
                            <small class="px-2"><i>Ebay Order amount may include also some taxes.</i></small>
                          </div>
                        <el-button type="success" size="small" slot="reference">
                          ${{ scope.row['OrderItem.totalPurchasedPrice'] }}
                        </el-button>
                      </el-popover>
                    </template>
                  </el-table-column>
                  <el-table-column label="Qty" min-width="60">
                    <template slot-scope="scope">
                        <el-button type="info" size="small" @click="viewOrderItems({ ... itemPagination, orderId: scope.row.orderId })">
                          {{ scope.row['OrderItem.quantity'] }}
                        </el-button>
                    </template>
                  </el-table-column>
                  <el-table-column label="Notes" min-width="180">
                    <template slot-scope="scope">
                      <div class="top-note" style="font-size: 13px;">
                        <div class="note-shipping-weight">
                            <span>Shipping Weight</span> : <span><b>{{  scope.row.defaultShippingWeight }}oz</b></span>
                        </div>
                        <div class="note-package-type">
                            <span>Package Type</span> : <span><b>{{  scope.row.defaultPackageType }}</b></span>
                        </div>
                        <div class="note-package-weight">
                            <span>Package Weight</span> : <span><b>{{  scope.row.defaultPackageWeight }}oz</b></span>
                        </div>
                        <div class="note-delivery-service">
                            <span>Delivery Service</span> :
                            <span><b v-html="getShippingService(scope.row.deliveryServiceSelected)"></b></span>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Date Created" min-width="100">
                    <template slot-scope="scope">
                      <span v-html="getFormattedData(scope.row.createdDate).replace(' ', '<br/>')"></span>
                    </template>
                  </el-table-column>
                  <el-table-column 
                    label="Actions"
                    min-width="145"
                    align="center"
                    class-name="action-column"
                    header-align="center">
                    <template slot-scope="scope">
                      <div v-if="isCommonTab">
                        <el-tooltip
                          v-if="isComplete(scope.row)"
                          class="item"
                          effect="dark"
                          content="Completed"
                          placement="top-start">
                          <el-button
                            type="success"
                            icon="fas fa-check-circle"
                            class="mr-1 mb-1"
                            circle>
                          </el-button>
                        </el-tooltip>
                        <el-tooltip
                          v-else
                          class="item"
                          effect="dark"
                          content="Complete Order"
                          placement="top-start">
                          <el-button
                            @click="completeOrder(scope.row)"
                            type="bs-info"
                            icon="fas fa-qrcode"
                            class="mr-1 mb-1"
                            circle>
                          </el-button>
                        </el-tooltip>
                        <el-tooltip
                          v-if="scope.row.extendedOrderID.length == 1 && scope.row.ebayOrderId !== ''"
                          class="item"
                          effect="dark"
                          content="Print Shipping Label on EBAY"
                          placement="top-start">
                          <el-link
                            :underline="false"
                            target="_blank"
                            :href="ebayStoreUrl + '/lbr/go?t=' + scope.row.ebayOrderId">
                            <el-button
                              type="bs-success"
                              icon="fas fa-print"
                              class="mr-1 mb-1"
                              circle>
                            </el-button>
                          </el-link>
                        </el-tooltip>
                        <el-tooltip
                          v-if="scope.row.localPrinted == 0"
                          class="item"
                          effect="dark"
                          content="Print Shipping Label From USPS"
                          placement="top-start">
                          <el-button
                            type="bs-success-dark"
                            icon="fas fa-print"
                            class="mr-1 mb-1"
                            circle>
                          </el-button>
                        </el-tooltip>
                        <el-tooltip
                          v-if="scope.row.extendedOrderID.length == 1 && hasShippingAddress(scope.row)"
                          class="item"
                          effect="dark"
                          content="View Shipping Address on Map"
                          placement="top-start">
                          <el-link
                            class="expand-item"
                            :underline="false"
                            target="_blank"
                            :href="'https://maps.google.com/?q=' + getShippingAddress(scope.row)">
                            <el-button
                              type="purple"
                              icon="el-icon-map-location"
                              class="mr-1 mb-1"
                              circle>
                            </el-button>
                          </el-link>
                        </el-tooltip>
                        <el-tooltip
                          v-if="hasVoidLabel(scope.row)"
                          class="item"
                          effect="dark"
                          content="Cancel Order"
                          placement="top-start">
                          <el-button
                            type="danger"
                            icon="fas fa-times-circle"
                            class="mr-1 mb-1"
                            circle>
                          </el-button>
                        </el-tooltip>
                        <el-tooltip
                          v-if="!hasVoidLabel(scope.row)"
                          class="item"
                          effect="dark"
                          content="Print Label Only"
                          placement="top-start">
                          <el-button
                            type="bs-success-deep"
                            icon="fas fa-sticky-note"
                            class="mr-1 mb-1"
                            circle>
                          </el-button>
                        </el-tooltip>
                        <el-tooltip
                          v-if="!hasVoidLabel(scope.row)"
                          class="item"
                          effect="dark"
                          content="Print Invoice Only"
                          placement="top-start">
                          <el-button
                            type="bs-primary-dark"
                            icon="fas fa-file-invoice"
                            class="mr-1 mb-1"
                            circle>
                          </el-button>
                        </el-tooltip>
                        <el-tooltip
                          v-if="!hasVoidLabel(scope.row)"
                          class="item"
                          effect="dark"
                          content="Shipments List"
                          placement="top-start">
                          <el-button
                            type="primary"
                            icon="far fa-paper-plane"
                            class="mr-1 mb-1"
                            @click="viewShipmentList(scope.row.id)"
                            circle>
                          </el-button>
                        </el-tooltip>
                        <el-tooltip
                          v-if="!hasVoidLabel(scope.row) && scope.row.shipmentTrackingNumber !== ''"
                          class="item"
                          effect="dark"
                          content="Track Order Shipment"
                          placement="top-start">
                          <el-button
                            type="info"
                            icon="fas fa-route"
                            class="mr-1 mb-1"
                            @click="viewTracking(scope.row.id)"
                            circle>
                          </el-button>
                        </el-tooltip>
                        <el-tooltip
                          v-if="!hasVoidLabel(scope.row) && scope.row.deliveredDate == 0"
                          class="item"
                          effect="dark"
                          content="Cancel Shipment"
                          placement="top-start">
                          <el-button
                            type="warning"
                            icon="fas fa-route"
                            class="mr-1 mb-1"
                            @click="cancelShipment(scope.row.id)"
                            circle>
                          </el-button>
                        </el-tooltip>
                      </div>
                      <div v-if="formFilter.tab === 'cancelled'">
                        <el-tooltip
                          v-if="hasShippingAddress(scope.row)"
                          class="item"
                          effect="dark"
                          content="View Shipping Address on Map"
                          placement="top-start">
                          <el-link
                            class="expand-item"
                            :underline="false"
                            target="_blank"
                            :href="'https://maps.google.com/?q=' + getShippingAddress(scope.row)">
                            <el-button
                              type="purple"
                              icon="fas fa-map-marker-alt"
                              class="mr-1 mb-1"
                              circle>
                            </el-button>
                          </el-link>
                        </el-tooltip>
                        <el-tooltip
                          v-if="scope.row.archived == 0"
                          class="item"
                          effect="dark"
                          content="Archive Order / Product(s) to Review"
                          placement="top-start">
                            <el-button
                              type="danger"
                              icon="fas fa-undo"
                              class="mr-1 mb-1"
                              circle>
                            </el-button>
                        </el-tooltip>
                      </div>
                      <div v-if="formFilter.tab === 'flagged'">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="Force tracking number update"
                          placement="top-start">
                            <el-button
                              type="warning"
                              icon="fas fa-paper-plane"
                              class="mr-1 mb-1"
                              @click="setShippingTrackingNumber(scope.row)"
                              circle>
                            </el-button>
                        </el-tooltip>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="dataTables_paginate paging_simple_numbers">
                <ul
                  class="pagination pagination-rounded mb-0"
                  style="justify-content: center;">
                  <b-pagination
                    v-model="formFilter.page"
                    :total-rows="tabCount[tab.link]"
                    :per-page="formFilter.pageSize"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </Layout>
  </div>
</template>

<style>

</style>
